.cards {
    pointer-events: none;
}
.card {
    /* max-width: 15rem; */
    color: #ffffffda;
    padding: 0.5rem;
    background-color: #fff;

    pointer-events: auto;

    transform: scale(1);
    opacity: 1;
    transition: all 150ms ease-in-out;

    display: flex;
    flex-direction: column;

}

.cards:hover>.card:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.card::before {
    content: "";
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    box-shadow: inset 0 0 3px #bb86fc;
    background-color: transparent;
    position: absolute;
    right: 0;
}

.cards:nth-child(even) {
    transform: translateY(8px);
}

.card:nth-child(n) {
    transform: rotate(-5deg);
}
.cards:hover>.card:not(:hover) {
    opacity: 0.5;
}