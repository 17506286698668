thead th {
    color: #FFFFFF;
    background-color: #1A2929;
}

/* required */
.tablewrapper {
    width: 95%;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: green;
    height: auto;
    padding: 5px;
}

/* only for demo purpose just for stlying. you can remove it */
table {
    font-family: arial;
    font-size: 13px;
    padding: 2px 3px;
    width: 100%;
    text-align: center;
}

table.responsive {
    background-color: #1a99e6;
    border-collapse: collapse;
    border-color: #fff
}

tbody,
td,
tfoot,
th,
thead,
tr {
    font-size: 16px;
    direction: ltr;
    border: 1px solid #000;
}

tr:nth-child(1) td:nth-of-type(2) {
    color: #333
}

tr:nth-child(odd) {
    background: #9EDFD3;
    border-right: 1px solid #1A2929;
}

tr:nth-child(even) {
    background: #fff;
}

@media (max-width: 992px) {
    .table>:not(caption)>*>* {
        padding: 2px;
        width: 50px !important;
        font-size: 10px;
        overflow: scroll;
    }
        thead th {
            padding: 2px;
                width: 50px !important;
                font-size: 10px;
                overflow: scroll;
        }
        .table {
            width: auto;
                display: block;
                overflow-x: auto;
        }
}
