

.card2 {
    display: block;
    top: 0px;
    position: relative;
    /* max-width: 262px; */
    background-color: #f2f8f9;
    border-radius: 30px;
    padding: 0.5rem;
    margin: 12px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #f2f8f9;
}


    .card2:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: white;
    }

    .card2:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -16px;
        right: -16px;
        background: #00838d;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        transform: scale(2);
        transform-origin: 50% 50%;
        transition: transform 0.15s ease-out;
    }

    .card2:hover:before {
        transform: scale(2.15);
    }