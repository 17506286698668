.my-li {
        padding: 10px;
            font-weight: bold;
            border: 1px solid #c00000;
            margin: 10px 5px;
            transition: 0.5s;
            cursor: pointer;
}
.my-li:hover {
        padding-left: 25px;
            font-weight: bold;
            border: 1px solid #2196f3;
            color:#2196f3;
            font-size: 18px;
            margin: 5px;
}